body {
  background-color: #e1ccbe;
  color: #000;
}

.main-content {
  height: calc(100vh - 160px) !important;
  border: solid 1px;
  border-radius: 9px;
  border-color: rgb(163, 163, 163);
}

.main-content-header {
  border-bottom: 1px solid;
  border-color: rgb(163, 163, 163);
  background-color: rgb(254, 254, 254);
  background-image: -webkit-linear-gradient(top, rgb(254, 254, 254), rgb(240, 240, 240));
  border-radius: 9px 9px 0 0;
}

.nav-link {
  color: rgb(102, 102, 102);
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.footer, .footer a {
  font-size: 10px;
  color: #6a6a6a;
}