/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 595px) {
    #artists-list {
        column-count: 2;
    }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
    #artists-list {
        column-count: 2;
    }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
    #artists-list {
        column-count: 3;
    }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
    #artists-list {
        column-count: 4;
    }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    #artists-list {
        column-count: 5;
    }
}
